import { useEffect, useState } from "react";
import LoginStyle from "styledComponents/loginStyle";
import { Checkbox, Form, Input } from "antd";
import ButtonCustum from "components/elements/buttons/Buttons";
import { UserOutlined, UnlockOutlined } from "@ant-design/icons";
import { CgLockUnlock } from "react-icons/cg";
import { useRouter } from "next/router";
import { postData } from "utils/fetchData";
import { Col, Row } from "antd";
import Link from "next/link";
import { userValidation } from "utils/services";

const Login = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const image = "/assets/img/loginprofile.png";
  const imagsvg = "/assets/img/login-2.svg";

  const [userImg, setUserImage] = useState("/assets/img/user1.png");
  const onFinish = async (values) => {
    setLoading(true);
    try {
      let response = await postData("/Admin/login", values);
      let token = response.token;

      if (token) {
        // window.localStorage.setItem(process.env.NEXT_PUBLIC_lOCALSTORAGE ,JSON.stringify(response));
        window.localStorage.setItem(
          process.env.NEXT_PUBLIC_TOKEN_NAME,
          response.token
        );
        window.localStorage.setItem(
          process.env.NEXT_PUBLIC_USER_ID,
          response.id
        );
        response?.imageBase64 &&
          window.localStorage.setItem(
            process.env.NEXT_PUBLIC_USER_IMAGE,
            response.imageBase64
          );

        document.cookie = `${process.env.NEXT_PUBLIC_TOKEN_NAME}=${response.token}`;
        router.push("/admin/icerikler");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <LoginStyle>
      <div className="headerofform">
        <Row>
          <Col span={16}>
            <div className="text-primary">
              <h5 className="text-primary">Giriş Yap</h5>
              <p>Yönetim Paneline Hoş Geldiniz.</p>
            </div>
          </Col>
          <Col span={8}>
            <img
              style={{ height: "auto", maxWidth: "100%" }}
              src={image}
              alt="no-image"
            />
          </Col>
        </Row>
      </div>

      <div>
        <img className="imgicon" src={imagsvg} alt="no-image" />{" "}
      </div>

      <Form
        name="normal_login"
        className="login-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Kullanıcı Adı"
          name="email"
          required
          rules={[
            {
              type: "email",
              message: "Lütfen geçerli email giriniz!",
            },
            {
              required: true,
              message: "Lütfen e-mail alanı boş olmamalı!",
            },
          ]}
        >
          <Input
            placeholder="kullanıcı adı yada Email"
            prefix={<UserOutlined />}
          />
        </Form.Item>

        <Form.Item
          label="Parola"
          name="password"
          required
          rules={[
            {
              validator: (_, value) => {
                return userValidation(value);
              },
            },
          ]}
        >
          <Input.Password placeholder="parola" prefix={<UnlockOutlined />} />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox className="chechbox">Beni hatırla</Checkbox>
        </Form.Item>

        <Form.Item style={{ width: "100%" }} className="login-form-button">
          <ButtonCustum loading={loading} type="primary" htmlType="submit">
            {loading ? "Giriş Yapılıyor.." : "Giriş Yap"}
          </ButtonCustum>
        </Form.Item>

        <Form.Item className="forgetpassword">
          <Link className="login-form-forgot" href="forgetPassword">
            <a>
              <CgLockUnlock style={{ marginRight: "5px" }} /> Parolamı unuttum?
            </a>
          </Link>
        </Form.Item>
      </Form>
    </LoginStyle>
  );
};
export default Login;

import styled from 'styled-components';

const loginStyle = styled.div`
position:relative;
top:150px;
width:450px;
margin: auto;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
border-radius: 4px;

.headerofform{
    background: #00adcc3d;
    padding:18px 18px 0 18px;
    border-radius: 4px 4px 0 0;
    .text-primary{
        // margin-top:12px;
    }
    h5{
        font-family: 'Poppins', sans-serif;
        font-size: 1em;
        color:#00adcc !important;
    }
    p{
        font-family: 'Poppins', sans-serif;
        font-size: 1em;
        color:#00adcc;
    }
}
.imgicon{
    width: 17%;
    padding: 23px;
    height: auto;
    border: 1px solid #eff2f7;
    border-radius: 50%;
    background: #eff2f7;
    position: relative;
    bottom: 35px;
    left: 20px;
}
form{
    padding:0 20px 20px 20px;
    //Start input label
    .ant-form-item-required{
        font-family: 'Poppins', sans-serif;
        font-size: 1em;
    }
    //End input label
    
    //Start chechbox
    .chechbox{
        font-family: 'Poppins', sans-serif;
        font-size: 1em;
    }
    //End chechbox

    //start button 
    .login-form-button{
        width :100%;
    }
    .ant-btn-primary{
        width:100% !important;
        font-family: 'Poppins', sans-serif;
        transition: 0.7s all;
        &:hover{
            background :#00adcced !important;
            border-color: #00adcced !important;
        }
    }
    //End button

    // Start forget password
    .forgetpassword{
        justify-content: center;
        text-align: center;
         a{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #74788d;
            &:hover{
                color: #424451;
            }
        }
    }
    //End forgert password
    .ant-form-item-explain-error {
        margin-top: 5px;
        font-family: 'Poppins', sans-serif;
        font-size: 0.9em;
    }

}
`;
export default loginStyle ;


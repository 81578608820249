import React from "react";
import { Button } from "antd";
import ButtonStyle from "./buttonStyle.js";

const ButtonCustum = (props) => {
  return (
    <>
      <ButtonStyle className="btn-Custum">
        <Button {...props}>
            {props.children}
          </Button>
      </ButtonStyle>
    </>
  );
};

export default ButtonCustum;

import styled from "styled-components";

var ButtonStyle = styled.div`
    .ant-btn-silme{
      background: #f06548;
      border: 1px solid #f06548;
      color: #fff;
      border-radius: 5px;
      transition: 0.6s ease-in-out;
      &:hover,&:focus,&:active{
        background: #ed4b29;
        color: #fff;
        border-color:#ed4b29;
      }
    }

   .ant-btn-dark{
    background: #405189;
    border: 1px solid #405189;
    color: #fff;
    border-radius: 5px;
    transition: 0.6s ease-in-out;
    &:hover,&:focus,&:active{
      background: #4e619f;
      color: #fff;
      border-color:#405189;
    }
   }

   .ant-btn-primary{
    background: #00adcc;
    border: 1px solid #00adcc;
    color: #fff;
    border-radius: 5px;
    transition: 0.6s ease-in-out;
    &:hover,&:focus,&:active{
      background: #4e619f;
      color: #fff;
      border-color:#405189;
    }

`;

export default ButtonStyle;
